<template>
    <div>
        <van-checkbox-group class="images-content" v-model="result">
            <div class="image-item-box" v-for="(item, index) in value" :key="index">
                <img :src="$fillOssPath(item.image)" @click="handlePreview(index)" />
                <van-checkbox :name="index" icon-size="2rem" checked-color="#07c160"></van-checkbox>
            </div>
        </van-checkbox-group>

        <van-image-preview v-model="preview" :images="ossImages" :startPosition="startPosition">
            <template v-slot:index="scope">
                <van-checkbox-group v-model="result">
                    <van-checkbox
                        v-for="(value, key) of value"
                        v-show="scope.index === key"
                        :name="key"
                        :key="key"
                        icon-size="3rem"
                        checked-color="#07c160"
                    ></van-checkbox>
                </van-checkbox-group>
            </template>
        </van-image-preview>
    </div>
</template>

<script>
export default {
    name: 'lk-select-image',
    props: {
        // imageObj[]    imageObj: { image: 'url', status: 1|2 }
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        max: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        ossImages() {
            return this.value.map((item) => this.$fillOssPath(item.image));
        },
        result: {
            get() {
                const result = [];
                this.value.forEach((item, index) => {
                    if (item.status === 1) {
                        result.push(index);
                    }
                });
                return result;
            },
            set(result) {
                // 记录上一步选择的值
                if (result.length > this.max) {
                    return this.$toast(`最多可选择${this.max}张图片`);
                }
                const images = this.value.map((item, index) => {
                    return {
                        image: item.image,
                        status: result.indexOf(index) >= 0 ? 1 : 0,
                    };
                });
                this.$emit('input', images);
            },
        },
    },
    data() {
        return {
            // result: [],
            preview: false, // 是否开启预览
            startPosition: 0, // 预览时从第几张开始

            prevSelected: [], // 用于记录上一步选择的结果。如果当前结果超出限制长度，则从此取之前的记录
        };
    },
    methods: {
        handlePreview(index) {
            this.preview = true;
            this.startPosition = index;
        },
        getInitialResult() {
            this.value.forEach((item, index) => {
                if (item.status === 1) {
                    this.result.push(index);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.images-content {
    font-size: 0;
}
.image-item-box {
    position: relative;
    display: inline-block;
    width: 10.3rem;
    height: 10.3rem;
    border-radius: 0.2rem;
    overflow: hidden;
    margin-bottom: 0.8rem;
    img {
        width: 100%;
        height: 100%;
    }
    &:nth-child(odd) {
        margin-right: 0.8rem;
    }
    .van-checkbox {
        position: absolute;
        right: 0.4rem;
        bottom: 0.4rem;
    }
    & /deep/ .van-checkbox__icon {
        border-radius: 50%;
        background-color: rgba(242, 242, 242, 0.3);
    }
}
/deep/ .van-image-preview__index {
    right: auto;
    top: auto;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, 0);
}
</style>
