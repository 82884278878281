<template>
    <div class="proposal-container" v-if="showProposal">
        <div class="task-title">{{ proposalData.task_title }}</div>
        <div class="proposal-tips">
            <span>{{
                proposalData.check_type === 0 ? '提案最多选择4个设计' : '最多可选择一款方案'
            }}</span>
        </div>
        <!-- 主体 -->
        <div class="proposal-content">
            <task-select-image v-model="images" :max="max"></task-select-image>
        </div>
        <!-- 固定页面尾部，操作按钮 -->
        <div class="opeartion-box">
            <van-button @click="handleReject">都不满意</van-button>
            <van-button type="primary" @click="handleShowConfirm">完成选择</van-button>
        </div>

        <!-- 录入修改意见 提案确认/终稿确认 -->
        <div class="remark-dialog-container" v-show="confirmVisible">
            <div class="remark-dialog-content">
                <img
                    v-for="(image, index) in selectedImages"
                    :src="$fillOssPath(image)"
                    :key="index"
                />
                <!-- 初稿确认输入建议，终稿输入显示注意事项 -->
                <van-field
                    v-if="proposalData.check_type === 0"
                    v-model="remark"
                    label="修改建议"
                    placeholder="修改建议，有助于设计师更好的进行下一步调整"
                    :rows="5"
                    type="textarea"
                ></van-field>

                <div class="notes-content" v-else>
                    <div class="notes-title">注意事项</div>
                    <div class="notes-box">
                        <p>
                            1.甲方应对其指定的项目负责人就本项目的验收确认等行为负责，该项目负责人视为甲方的授权代表，其操作确认行为同甲方的盖章行为具有同等法律效力。
                        </p>
                        <p>
                            2.甲方选定并付清费用的方案的知识产权归甲方所有,甲方未选定的方案的知识产权归乙方所有。
                        </p>
                    </div>
                </div>
            </div>
            <div class="opeartion-box">
                <van-button @click="handleRePick">重新选择</van-button>
                <van-button type="primary" @click="handleSubmit">确认提交</van-button>
            </div>
        </div>

        <!-- 驳回 -->
        <div class="reject-container" v-show="rejectVisible">
            <van-form>
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="rejectForm.reason"
                    label="不满意原因"
                    placeholder="点击选择不满意原因"
                    @click="showRejectReasonPicker = true"
                />
                <van-popup v-model="showRejectReasonPicker" position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        @confirm="handleRejectReasonConfirm"
                        @cancel="showRejectReasonPicker = false"
                    />
                </van-popup>
                <van-field
                    v-model="rejectForm.remark"
                    rows="5"
                    autosize
                    label="修改建议"
                    type="textarea"
                    maxlength="300"
                    placeholder="请输入建议"
                    show-word-limit
                />
            </van-form>
            <div class="opeartion-box">
                <van-button @click="handleRePick">重新选择</van-button>
                <van-button type="primary" @click="handleSubmitRject">提交</van-button>
            </div>
        </div>

        <!-- 通过结果展示 -->
        <lk-result
            :visible="resultVisible"
            title="提交成功"
            confirm-button-text="查看提案记录"
            :confirm-button-href="`/proposal/note/${taskNo}/${code}`"
        >
            您的反馈已生成提案记录，邦女郎将根据您的建议修改后再次发起提案，请您耐心等待
        </lk-result>
    </div>
</template>

<script>
/*
    TODO: 根据 check_type 字段，判断是初稿还是终稿 0初稿 1终稿
*/
import TaskSelectImage from './components/TaskSelectImage';
import LkResult from '@/components/LkResult';

import { getProposal, putConfirmProposal, putProposalReject } from '@/api/task';

import { REJECT_REASON_MAP } from '@/constant/task';

export default {
    name: 'task-proposal',
    components: { TaskSelectImage, LkResult },
    computed: {
        // 被选中的图， 用于用户确认时显示
        selectedImages() {
            return this.images.filter((item) => item.status == 1).map((item) => item.image);
        },
        taskNo() {
            return this.$route.params.taskNo;
        },
        code() {
            return this.$route.params.code;
        },
        // 最多选择几张图？ 提案最多4个，方案（终稿）做多1个
        max() {
            return this.proposalData.check_type === 0 ? 4 : 1;
        },
    },
    data() {
        return {
            showProposal: false,

            proposalData: {},

            images: [],

            imageMap: {}, // 记录每张图片所属的子采购单ID，用于最后提交时整理数据

            confirmVisible: false, // 是否显示确认提案提交，录入建议

            finalConfirmVisible: true, // 是否显示方案确认

            remark: '',

            resultVisible: false,

            rejectForm: {
                reason: '',
                remark: '',
            },
            rejectVisible: false,
            showRejectReasonPicker: false,

            columns: Object.keys(REJECT_REASON_MAP),
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        // 获取采购单内容
        init() {
            getProposal(this.taskNo, this.code).then(([response, error]) => {
                if (!error) {
                    const state = response.data.state;
                    // 如果提案已确认，跳转提案笔记
                    if (state === 3) {
                        this.$router.push({
                            path: `/proposal/note/${this.taskNo}/${this.code}`,
                            query: { phone: this.$route.query.phone },
                        });
                    }
                    const images = response.data.item.map((item) => item?.images || []).flat();
                    const selected = images.find((item) => item.status == 1);
                    if (state !== 3 && !selected) {
                        return this.$toast('请等待邦女郎提交确认书');
                    }

                    // 不满足上面条件，则允许展示
                    this.showProposal = true;

                    this.proposalData = response.data;

                    const items = response.data.item;

                    this.images = items.map((item) => item.images).flat();

                    this.remark = response.data.item[0].customer_remark;

                    const imageMap = {};
                    items.forEach((item) => {
                        const id = item.id;
                        item.images.forEach((image) => {
                            imageMap[image.image] = id;
                        });
                    });
                    if (response.data.check_type !== 0) {
                        document.title = '方案确认';
                    }
                    this.imageMap = imageMap;
                } else {
                    this.$toast({
                        message: error.msg,
                        duration: 0,
                    });
                }
            });
        },
        handleReject() {
            this.rejectVisible = true;
        },
        // 显示提交窗口。 根据 check_type 显示 提案确认 和 方案确认弹窗
        handleShowConfirm() {
            // 如果用户未选择任何图片，进行提示
            if (this.selectedImages.length === 0) {
                return this.$toast('请选择方案后进行下一步');
            }
            this.confirmVisible = true;
        },
        handleSubmit() {
            this.$dialog
                .confirm({
                    message: '要提交已选方案么？提交后将不能重选～',
                    confirmButtonText: '提交',
                })
                .then(() => {
                    // 开启loading
                    this.$toast.loading('正在提交...');
                    // 计算提交数据
                    const data = {
                        images: {},
                        remark: this.remark,
                    };
                    // 图片对象需要存储到不同采购单子项下
                    this.images.forEach((item) => {
                        const id = this.imageMap[item.image];

                        // 如果没有相关结构，则先创建
                        if (!data.images[id]) {
                            data.images[id] = [];
                        }
                        // 当前子项添加到对应结构下
                        data.images[id].push(item);
                    });
                    // 提交
                    putConfirmProposal(this.proposalData.id, data).then(([, error]) => {
                        this.$toast.clear();
                        if (!error) {
                            this.resultVisible = true;
                        } else {
                            this.$toast(error.msg);
                        }
                    });
                });
        },
        handleRePick() {
            this.confirmVisible = false;
            this.rejectVisible = false;
        },

        // 保存驳回原因
        handleRejectReasonConfirm(value) {
            this.rejectForm.reason = value;
            this.showRejectReasonPicker = false;
        },
        handleSubmitRject() {
            const { reason, remark } = this.rejectForm;

            if (!reason) return this.$toast('请选择不满意原因');
            if (!remark) return this.$toast('请填写修改建议');

            const reasonCode = REJECT_REASON_MAP[reason];
            const proposalId = this.proposalData.id;

            this.$toast.loading('正在提交...');

            putProposalReject(proposalId, reasonCode, remark).then(([, error]) => {
                this.$toast.clear();
                if (!error) {
                    this.resultVisible = true;
                } else {
                    this.$toast(error.msg);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import './styles/index.scss';

.task-title {
    padding: 1rem 1rem 0;
    font-size: 1.3rem;
    font-weight: bold;
}

.proposal-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .proposal-tips {
        padding: 0.6rem 1rem 0rem;
        height: 1.5rem;
        font-size: 0.75rem;
        span {
            line-height: 1.5rem;
            color: #999;
        }
    }
    .proposal-content {
        flex: 1;
        padding: 1rem 1rem 0rem;
        overflow-y: scroll;
    }
}
.remark-dialog-container {
    position: fixed;
    z-index: 9;
    background-color: #f7f8fa;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .remark-dialog-content {
        flex: 1;
        width: 100%;
        overflow-y: scroll;
        img {
            margin: 1.5vw 2.5%;
            width: 95%;
            margin-bottom: 0.5rem;
        }
    }
}
.notes-content {
    padding: 0.5rem 1rem;
    .notes-title {
        line-height: 1.5;
    }
    .notes-box {
        background-color: #ededed;
        border-radius: 0.2rem;
        padding: 0.5rem;
        margin: 0.5rem 0;
        p {
            line-height: 1.5;
        }
        p:nth-child(1) {
            margin-bottom: 0.8rem;
        }
    }
}

.reject-container {
    display: none;
    position: fixed;
    z-index: 9;
    background-color: #f7f8fa;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
